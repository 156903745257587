import {find, findIn} from "@elements/dom-utils"
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    let infoArea = find('#infoModal');
    if(infoArea){
        let infoModal = new Modal(infoArea);
        if (!sessionStorage.getItem('info')) {
            infoModal.show();
        }

        infoArea.addEventListener('hide.bs.modal', function () {
            sessionStorage.setItem('info','closed');
        });

    }
}