import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as affix from '@elements/affix';
affix.init({mediaQuery: '(min-width: 768px)'});

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-right'
});

import * as hotspot from './scripts/hotspot';
hotspot.init();

import * as hotspotPopup from './scripts/hotspot-popup';
hotspotPopup.init();

import * as infoModal from './scripts/info-modal';
infoModal.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__list-item list-inline-item js-anchor-nav__item"><a class="js-anchor-nav__link nav-link anchor-nav__link js-smoothscroll__link" data-scroll-offset="150" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 150
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as anchorNavExtension from './scripts/anchor-nav-extension';
anchorNavExtension.init();

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: 150});

import * as countdown from '@elements/countdown';
countdown.init({
    prependZero: true
});