import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, closest, on, findIn, find, addClass, hasClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-hotspot-popup',
    btn: '.js-hotspot-popup__btn',
    popup: '.js-hotspot-popup__popup'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let buttons = findAllIn(selectors.btn, baseElement);
        let popups = findAllIn(selectors.popup, baseElement);

        setPopupPosition(popups);

        buttons.map(function (item) {
            on('click', function (evt) {
                let target = item.dataset.popupTarget || '';
                if (target) {
                    let targetItem = find(target);
                    if (targetItem) {
                        let removeActiveClass = removeClass('is-active');
                        if (hasClass('is-active', targetItem)) {
                            removeClass('is-active', targetItem);
                            removeClass('is-active', item);
                            popups.map(x => removeActiveClass(x));
                            buttons.map(x => removeActiveClass(x));
                        } else {
                            popups.map(x => removeActiveClass(x));
                            buttons.map(x => removeActiveClass(x));
                            addClass('is-active', targetItem);
                            addClass('is-active', item);
                        }
                    }
                }
            }, item);

        });

    });
}

function setPopupPosition(popups) {
    popups.map(function (popup) {

        const isMobile = matchMedia('(max-width: 767px)').matches;

        if (isMobile) {
            addClass('hotspot-popup__popup--mobile', popup);
        }

        let id = '#' + popup.id;
        let button = document.querySelector(`[data-popup-target="${id}"]`);

        if (!isMobile && button.getBoundingClientRect().right + 170 > window.innerWidth) {
            addClass('hotspot-popup__popup--left', popup);
        }

    });
}
