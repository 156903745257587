import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, closest, on, find, toggleClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-hotspot',
    btn: '.js-hotspot__btn',
    items: '.js-hotspot__target'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let items = findAllIn(selectors.items, baseElement);
        let buttons = findAllIn(selectors.btn, baseElement);

        buttons.map(function(item) {
            on('click', function (evt) {
                let target = item.dataset.hotspotTarget || '';
                if (target) {
                    let targetItem = find(target);
                    if (targetItem) {
                        let removeActiveClass = removeClass('is-active');
                        items.map(x => removeActiveClass(x));
                        buttons.map(x => removeActiveClass(x));

                        toggleClass('is-active', item);
                        toggleClass('is-active', targetItem);

                        centerItem(targetItem);
                    }
                }
            }, item);
        });

        items.map(function(item) {
            on('click', function (evt) {
                let target = item.id || '';

                if (target) {
                    let targetItem = find(`[data-hotspot-target='#${target}'`);
                    if (targetItem) {
                        let removeActiveClass = removeClass('is-active');
                        buttons.map(x => removeActiveClass(x));
                        items.map(x => removeActiveClass(x));

                        toggleClass('is-active', item);
                        toggleClass('is-active', targetItem);

                        centerItem(item);
                    }
                }
            }, item);
        });
            

    });
}

function centerItem(item) {
    let parentUl = closest('.scrolling-content', item),
        childPos = item.offsetTop,
        parentScroll = item.parentNode.scrollTop,
        offset = childPos;


    parentUl.scrollTo({
        top: offset,
        behavior: 'smooth'
    });
}